<template>
  <div class="orderrefund">
    <van-nav-bar
      :title="title"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-form @submit="onSubmit">
      <van-field
        v-model="typevalue"
        name="typevalue"
        label="处理方式"
        placeholder="选择处理方式"
        :rules="[{ required: true, message: '请选择处理方式' }]"
      >
        <template #input>
          <van-dropdown-menu>
            <van-dropdown-item @change="typeChange" v-model="type" :options="option" />
          </van-dropdown-menu>
        </template>
        <template #right-icon>
          <van-icon name="arrow" />
        </template>
      </van-field>
      <van-field
        v-model="reason"
        name="reason"
        label="退款原因"
        placeholder="选择退款原因"
        :rules="[{ required: true, message: '请选择退款原因' }]"
      >
        <template #input>
          <van-dropdown-menu>
            <van-dropdown-item @change="reasonChange" v-model="type2" :options="option2" />
          </van-dropdown-menu>
        </template>
        <template #right-icon>
          <van-icon name="arrow" />
        </template>
      </van-field>
      <van-field
        v-model="message"
        name="message"
        label="退款说明"
        rows="2"
        autosize
        type="textarea"
        maxlength="50"
        placeholder="选填"
      />
      <van-field
        v-if="typevalue == '2' ? false : true"
        v-model="money"
        name="money"
        label-width="85"
        label-class="label_class_title"
        :placeholder="'最大金额' + ordermoney"
        :rules="[{ required: true, message: '退款金额不能为0' }]"
        @blur="inputBlur"
        @input="moneyInput"
      >
        <template #label>
          <span style="display: inline-block;width: 60px; margin-right: 12px;">退款金额</span>
          <span>&yen;</span>
        </template>
      </van-field>
      <van-field name="uploader" label="上传凭证">
        <template #input>
          <van-uploader
            v-model="uploader"
            :before-read="beforeRead"
            multiple
            :after-read="afterRead"
            :before-delete="beforDelete"
            :delete="deleteImg"
          />
        </template>
      </van-field>
      
      <div style="margin: 16px;">
        <van-button round block type="info" :loading="btnLoad" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      orderid: 0, // 订单id
      ordermoney: 0, // 订单金额
      orderstatus: 0, // 订单状态
      optionid: 0, // 订单状态
      title: "", // 页面标题
      order: {}, // 订单信息
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      type: 0, // 退款方式
      type2: 0, // 退款理由
      typevalue: "", // 退款方式
      reason: "", // 退款理由，
      message: "", // 退款说明
      money: "", // 退款金额
      uploader: [], // 已上传图片链接地址
      option: [],
      option2: [
        { text: "不想要了", value: 0 },
        { text: "卖家缺货", value: 1 },
        { text: "拍错了/订单信息错误", value: 2 },
        { text: "其他原因", value: 3 },
      ],
      imgIndex: 0, // 预览图片的位置
      merchid: 0,
      shareid: 0,
      uploadImg: [], // 图片
      btnLoad: false, // 按钮加载中
    }
  },
  mounted() {

    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, this.title);

    
    this.orderstatus = this.$route.query.status;
    this.optionid = this.$route.query.optionid;
    let option = [];
    if (this.orderstatus == 1) {
      this.title = "退款申请";
      option = [{ text: "退款(仅退款不退货)", value: 0 }];
    } else if(this.orderstatus >= 2) {
      this.title = "售后申请"
      option = [
        { text: "退款(仅退款不退货)", value: 0 },
        { text: "退货退款", value: 1 },
        { text: "换货", value: 2 }
      ];
    }
    this.option = option;
    this.orderid = this.$route.query.id;
    this.ordermoney = this.$route.query.money;
    this.typevalue = this.option[this.type].value;
    this.reason = this.option2[this.type2].text;
    this.init();
  },
  methods: {
    init() {
      this.btnLoad = false;
      console.log("申请退款页面");
      this.getrefund()
    },
    getrefund() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded"
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(
          _that.$store.state.domain + "web/order/get_maxmoney",
          _that.$qs.stringify({
            id: _that.orderid
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            this.ordermoney = res.data.data.valid_re_price
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 返回上一页
    onClickLeft() {
      this.$router.go(-1);
    },
    // 申请退款
    onSubmit() {
      console.log("确认按钮");
      let _that = null;
      _that = this;
      _that.btnLoad = true;
      _that.$dialog.alert({
        title: "申请退款",
        message: "确定要申请退款",
        showCancelButton: true,
        beforeClose: function(action, done) {
          done();
          console.log(action);
          if (action == "confirm") {
            _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded"
            _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
            _that.$axios
              .post(
                _that.$store.state.domain + "web/order/refund",
                _that.$qs.stringify({
                  orderid: _that.orderid, // 订单id
                  optionid: _that.optionid, // 产品sku ID
                  ordermoney: _that.ordermoney, // 订单金额
                  typevalue: _that.typevalue, // 退款方式
                  reason: _that.reason, // 退款理由，
                  message: _that.message, // 退款说明
                  money: _that.money, // 退款金额
                  uploader: _that.uploadImg, // 已上传图片链接地址
                  merchid: _that.merchid
                })
              )
              .then(response => {
                console.log(response);
                if (response && response.data && response.data.code == 100000) {
                  _that.$toast(response.data.msg ? response.data.msg : "申请成功");
                }else{
                  _that.$toast(response.data.msg ? response.data.msg : "");
                }
                setTimeout(() => {
                  _that.$router.back();
                }, 2000);
              })
              .catch(error => {
                console.log(error);
              });
          }
          if (action == "cancel") {
            console.log("点击取消按钮");
          }
        }
      })
    },
    // 
    moneyInput(val) {
      this.money = val;
    },
    // 如果输入金额超出订单金额后自动改成订单金额
    inputBlur(val) {
      let _that = null;
      _that = this;
      if (parseFloat(_that.money) > parseFloat(_that.ordermoney)) {
        _that.money = _that.ordermoney;
      }
    },
    // 选择退款原因
    reasonChange(val) {
      console.log(val, this.type2);
      this.reason = this.option2[val].text;
    },
    // 处理方式
    typeChange(val) {
      this.typevalue = this.option[val].value;
    },
    // 文件上传之前判断格式
    beforeRead(file) {
      let types = null;
      types = this.isType2(file);
      if (types == "Array") {
        file.forEach(res => {
          if (res.type !== 'image/jpeg' && res.type !== 'image/gif' && res.type !== 'image/pjpeg' && res.type !== 'image/png'  && res.type !== 'image/JPG') {
            this.$toast('只能上传上传图片');
            return false;
          }
        })
      }else{
        if (file.type !== 'image/jpeg' && file.type !== 'image/gif' && file.type !== 'image/pjpeg' && file.type !== 'image/png'  && file.type !== 'image/JPG') {
          this.$toast('只能上传上传图片');
          return false;
        }
      }
      return true;
    },
    afterRead(file) {
      // 开始上传文件
      console.log(file);
      let types = null;
      types = this.isType2(file);
      if (types == "Array") {
        file.forEach(res => {
          this.uploaderFile(res);
        })
      }else{
        this.uploaderFile(file);
      }
    },
    uploaderFile(file) {
      let _that = null;
      _that = this;
      const fordata = new FormData();
      fordata.append('file',file.file);
      // fordata.append('name',file.file.name);
      fordata.append('dir','image/tuikuan/');
      fordata.append('merchid',_that.merchid);

      _that.$axios.defaults.headers["Content-Type"] = "multipart/form-data"
      _that.$axios.post(
        "https://api.midbest.cn/admin/image/shangchuan",
        fordata
      ).then( response =>{
        console.log(response);
        if (response.data.code == 100000) {
          // const obj = {
          //   url: response.data.img_url
          // }
          // _that.uploader.push(obj);
          _that.uploadImg.push(response.data.img_url);
        }
      }).catch( error =>{
        console.log(error);
      })
    },
    // 判断变量类型
    isType2(type) {
      return (/^\[object\s(.*)\]$/.exec(Object.prototype.toString.call(type)))[1];
    },
    // 删除图片
    beforDelete(file, detail) {
      this.imgIndex = detail.index;
      return true;
    },
    // 执行删除图片
    deleteImg() {
      this.uploadImg.splice(this.imgIndex, 1);
    }
  }
}
</script>
<style lang="less">
.orderrefund {
  position: relative;
  background-color: #fff;
  width: 100%;
  min-height: 100%;
  z-index: 1;
  overflow: hidden;
  .van-nav-bar {
    .van-icon {
      color: #333;
    }
  }
  .van-field{
    line-height: 30px;
    .van-field__label {
      width: 60px;
    }
    .label_class_title{
      margin-right: 8px;
    }
    .van-field__value {
      flex: 1;
      .van-dropdown-menu {
        width: 100%;
        .van-dropdown-menu__bar {
          height: 30px;
          line-height: 30px;
          box-shadow: none;
          .van-dropdown-menu__item {
            -webkit-justify-content: start;
            justify-content: start;
            .van-dropdown-menu__title {
              font-size: 14px;
            }
          }
          .van-dropdown-menu__title::after {
            border: 0;
          }
        }
      }
    }
  } 
}
</style>
